import styled from "react-emotion";

const SearchInput = styled.input`
    appearance: none;
    padding: 1.75em 1em 0.75em;
    flex-grow: 1;
    border: 1px solid #A7B0CA;
    cursor: pointer;
    margin-bottom: 1em;
    color: #000000;
    border-radius: 0;
    font-size: 1rem;
    font-weight: 600;
    transition: background-color 0.3s ease-in-out;
    background-color: rgba(255, 255, 255, 0.85);

    &:hover {
        border: 1px solid #279AF1;
    }

    &:focus {
        border: 1px solid #0067F2;
        outline: none;
        background-color: rgb(255, 255, 255);
    }

    @media screen and (min-width: 768px) {
        margin-bottom: 0;
        border-right: none;
    }
`;

export default SearchInput;
