import styled from "react-emotion";

const SearchLabel = styled.label`
    position: absolute;
    top: 1.1em;
    left: 1rem;
    color: #7280AA;
    line-height: 1.5;
    cursor: pointer;
    transition: all .3s ease-out;
    font-size: 1.125em;
    font-weight: 600;
    letter-spacing: -0.02em;

    ${props =>
    props.isFocus &&
        `font-size: .75em;
        line-height: 16px;
        letter-spacing: .02em;
        top: .6666em;
        color: #0067F2;`
    };

    @media screen and (min-width: 768px) {
        top: 1.12em;
    }
`;

export default SearchLabel;
