import React from "react";

import Container from "../Container";
import HeroSubtitle from "./HeroSubtitle";
import HeroTitle from "./HeroTitle";

const HeroContent = () => {
    return (
        <Container>
            <HeroTitle>
                Купить доменное&nbsp;имя
            </HeroTitle>
            <HeroSubtitle>
                Зарегистрируйте домен у&nbsp;одного из&nbsp;лидеров рынка по&nbsp;отличной цене. Более 200&nbsp;зон на&nbsp;выбор.
            </HeroSubtitle>
        </Container>
    );
};

export default HeroContent;
