import styled from "react-emotion";

const HeroSubtitle = styled.p`
    font-size: 1.25em;
    line-height: 1.45;
    color: #ffffff;
    margin: 0 auto 2em;
    max-width: 27em;
    letter-spacing: -0.02em;

    @media screen and (min-width: 1024px) {
        max-width: 32em;
    }
`;

export default HeroSubtitle;
