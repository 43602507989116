import React from "react";
import styled from "react-emotion";

import Hero from "./Hero";
import HeroContent from "./HeroContent";
import HeroSearch from "./HeroSearch";
import Container from "./Container";

const HeroBlock = () => {

    const FormData = {
        "inputType": "text",
        "inputName": "name",
        "buttonType": "submit",
        "buttonName": "submit",
        "buttonText": "Найти домен",
        "label": "Введите домен или слово",
        "action": "https://domains.nethouse.ru/domains",
        "method": "POST"
    };

    return (
        <Hero>
            <Container>
                <HeroOverlay />
                <HeroContent />
                <HeroSearch { ...FormData } />
            </Container>
        </Hero>
    );
};

export default HeroBlock;

const HeroOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #08090A;
    opacity: 0.55;
    z-index: -1;
`;
