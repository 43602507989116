import React from "react";
import PropTypes from "prop-types";

import SearchInput from "./SearchInput";
import SearchLabel from "./SearchLabel";
import SearchForm from "./SearchForm";
import SearchButton from "./SearchButton";

export default class HeroSearch extends React.Component {
    state = {
        focus: false
    };

    onFocus = () => {
        this.setState(() => {
            return{
                focus: true
            };
        });
    };

    onBlur = () => {
        const input = document.getElementById("domain-input");
        if (input.value.length == 0) {
            this.setState(() => {
                return {
                    focus: false
                };
            });
        }
    };

    render() {
        const { action, inputType, inputName, buttonText, label } = this.props;
        return (
            <SearchForm>
                <SearchLabel htmlFor="domain-input" isFocus={this.state.focus}>{label}</SearchLabel>
                <SearchInput autoComplete="off" type={ inputType } name={ inputName } aria-label={ label } id="domain-input" onFocus={this.onFocus} onBlur={this.onBlur} />
                <SearchButton href={action} target="_blank">{ buttonText }</SearchButton>
            </SearchForm>
        );
    }
}

HeroSearch.propTypes = {
    action: PropTypes.string.isRequired,
    inputType: PropTypes.string.isRequired,
    inputName: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};
