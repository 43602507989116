import styled from "react-emotion";

const HeroTitle = styled.h1`
    font-size: 2.75em;
    line-height: 1.25;
    color: #ffffff;
    margin: 0 auto 1em;
    font-weight: bold;
    margin-bottom: 0.9em;
    letter-spacing: -0.013em;

    @media screen and (min-width: 768px) {
        margin-bottom: 0.8em;
    }

    @media screen and (min-width: 1024px) {
        font-size: 3em;
    }
`;

export default HeroTitle;
