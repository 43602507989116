import styled from "react-emotion";


const Hero = styled.div`
    background: #161925 url('//s.siteapi.org/prs/04/8c/82/78e1f7ba074e7b7e4ab5decd33325d42.jpg') no-repeat 50% 50%;
    background-size: cover;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1600 - 320)));
    text-align: center;
    width: 100%;
    padding: 3em 0 3.5em;
    position: relative;
    z-index: 1;

    @media screen and (min-width: 768px) {
        padding: 3.5em 0 5em;
    }
`;

export default Hero;
